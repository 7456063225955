@tailwind base;
@tailwind components;
@tailwind utilities;

.fade {
  animation: fade 1s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
